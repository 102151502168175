import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../components/layout'

const useStyles = makeStyles((theme) => ({
    Header: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
  
      },
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        textAlign: 'center',
      },
    },
    Body: {
  
        [theme.breakpoints.up('xs')]: {
            marginTop: '30%',
        },
        [theme.breakpoints.up('sm')]: {
    
        },
        [theme.breakpoints.up('md')]: {
          marginTop: '20%',
        },
        [theme.breakpoints.up('lg')]: {
          marginTop: '12%',
        },
    }
  })
  )
  
  const WorkshopPrequisites = () => {
    const classes = useStyles();
  
    return (
      <Layout>
        <div className={classes.Body}>
        <div className={classes.Header}>
          <h1>WebdriverIO Workshop Prerequisites</h1>
          </div>
          <br/>
          <h3>What You Will Need To Bring</h3>
          <p>As this is an online event you will need:</p>
          <ul>
            <li>A laptop, OS X, Linux or Windows that you can install software on and connect to wifi with</li>
            <li>Strong, stable internet connection that allows you to connect and participate in the workshop</li>
            <li>A headset so that you can collaborate with fellow attendess and the instructor Julia</li>
            <li>Chrome Browser</li>
            <li>IDE that supports JavaScript - I will be using <a href='https://code.visualstudio.com/' target="_blank" rel="noopener noreferrer">VS Code</a></li>
            <li><a href='https://nodejs.org/en/' target="_blank" rel="noopener noreferrer">Node.js v14.15.3 LTS</a></li>
            <li><a href='https://www.oracle.com/java/technologies/javase-downloads.html' target="_blank" rel="noopener noreferrer">Java JDK</a></li>
          </ul>
          <br/>
          <h3>What You Will Need To Know</h3>
          <p>As this is a WebdriverIO workshop:</p>
          <ul>
            <li>At least a basic understanding of programming in Javascript. <a href='https://www.youtube.com/watch?v=PkZNo7MFNFg' target="_blank" rel="noopener noreferrer">You can brush up here.</a></li>
            <li>Basic understanding of Node.js, git and use of the command line</li>
            <li>A headset so that you can collaborate with fellow attendess and the instructor Julia</li>
          </ul>
        </div>
        </Layout>
    )
  }
  
  export default WorkshopPrequisites

