import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    toTop: {
        zIndex: 2,
        position: 'fixed',
        bottom: '2vh',
        backgroundColor: '#DCDCDC',
        color: 'black',
        "&:hover, &.Mui-focusVisible": {
            transition: '0.3s',
            color: '#397BA6',
            backgroundColor: '#DCDCDC'
        },
        [theme.breakpoints.up('xs')]: {
            right: '5%',
            backgroundColor: 'rgb(220,220,220,0.7)',
        },
        [theme.breakpoints.up('lg')]: {
            right: '6.5%',
        },
    }
})
)

const Scroll = ({
    direction = `up`,
    by,
    to,
    showBelow,
    className,
    size = `2.5em`,
}) => {

    const classes = useStyles();
    if (![`up`, `down`].includes(direction))
        throw TypeError(
            `Scroll component's direction prop must be either 'up' or 'down'`
        )
    if (to && (typeof to !== `number` || to <= 0))
        throw TypeError(`Scroll component's to prop must be a positive number`)
    if (by && typeof by !== `number`)
        throw TypeError(`Scroll component's by prop must be a number`)

    const [show, setShow] = useState(showBelow ? false : true)

    const scroll = ({ mode, to }) =>
        window[`scroll` + mode]({ top: to, behavior: `smooth` })

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        if (to) scroll({ mode: `To`, to: to * window.innerHeight })
        else if (by) scroll({ mode: `By`, to: by * window.innerHeight })
        else if (direction === `up`) scroll({ mode: `To`, to: 0 })
        else scroll({ mode: `To`, to: document.body.scrollHeight })
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    return (
        <div>
            {show &&
                <IconButton onClick={handleClick} className={classes.toTop} aria-label="to top" component="span">
                    <ExpandLessIcon />
                </IconButton>
            }
        </div>
    )
}

export default Scroll